<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__top">
        <div class="footer__logo">
          <img src="@/assets/img/icons/logo-footer.svg" alt="" />
        </div>
        <div class="footer__content">
          <div class="footer__content-item">
            <div class="footer__dropdown">
              <div
                class="footer__dropdown-title"
                :class="{ 'is-dropdown': $isMobile(), active: isShowDropdown }"
                @click="toogleDropdown"
              >
                Категории
                <img
                  v-if="$isMobile()"
                  src="@/assets/img/icons/dropdown-arrow-white.svg"
                  alt=""
                />
              </div>
              <ul v-if="isShowDropdown" class="footer__dropdown-list">
                <li class="footer__dropdown-item">
                  <a class="footer__dropdown-link" href="#"> Винная карта </a>
                </li>
                <li class="footer__dropdown-item">
                  <a class="footer__dropdown-link" href="#">
                    Крепкий алкоголь
                  </a>
                </li>
                <li class="footer__dropdown-item">
                  <a class="footer__dropdown-link" href="#">
                    Аперитивы и вермуты
                  </a>
                </li>
                <li class="footer__dropdown-item">
                  <a class="footer__dropdown-link" href="#"> Пиво и сидр </a>
                </li>
                <li class="footer__dropdown-item">
                  <a class="footer__dropdown-link" href="#">
                    Ликеры и настойки
                  </a>
                </li>
                <li class="footer__dropdown-item">
                  <a class="footer__dropdown-link" href="#">
                    Напитки и снеки
                  </a>
                </li>
                <li class="footer__dropdown-item">
                  <a class="footer__dropdown-link" href="#">
                    Табачная продукция
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <ul class="footer__list">
            <li class="footer__list-item">
              <a class="footer__list-link" href="#">Акции</a>
            </li>
            <li class="footer__list-item">
              <a class="footer__list-link" href="#">Нагреваемые устройства</a>
            </li>
            <li class="footer__list-item">
              <a class="footer__list-link" href="#">Готовим коктейли</a>
            </li>
            <li class="footer__list-item">
              <a class="footer__list-link" href="#">Наши партнеры</a>
            </li>
          </ul>
          <ul class="footer__list">
            <li class="footer__list-item">
              <a class="footer__list-link" href="#">Новости</a>
            </li>
            <li class="footer__list-item">
              <a class="footer__list-link" href="#">Полезные статьи</a>
            </li>
            <li class="footer__list-item">
              <a class="footer__list-link" href="#">О клубе</a>
            </li>
            <li class="footer__list-item">
              <a class="footer__list-link" href="#">Правила клуба</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container">
        <div class="footer__bottom-inner">
          <div class="footer__bottom-rights">
            2022©partyclub.kz Все права защищены.
          </div>
          <div class="footer__bottom-content">
            <div class="footer__bottom-limitation">
              <img src="@/assets/img/icons/limitation21.svg" alt="" />
              <p class="footer__bottom-text">
                Чрезмерное употребление алкоголя вредит вашему здоровью!
                Информация не предназначена для лиц, не достигших возраста 21
                года.
              </p>
            </div>

            <p class="footer__bottom-text">
              В соответствии со статьёй 31 Закона РК от 12 апреля 2004 года №
              544 «О регулировании торговой деятельности», настоящий сайт несет
              информативный характер, не осуществляет продажи по интернету. В
              связи с этим данная информация не является рекламой и
              предназначена исключительно для ознакомительных целей. Продажи
              осуществляются исключительно в торговой сети Magnum.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { getSocialLink } from "@/api/links";
export default {
  name: "AppFooter",
  data() {
    return {
      isShowDropdown: true,
    };
  },
  created() {
    getSocialLink().then((social) => {
      this.social = social;
    });
  },
  mounted() {
    if (this.$isMobile()) {
      this.isShowDropdown = false;
    }
  },
  methods: {
    toogleDropdown() {
      if (this.$isMobile()) {
        this.isShowDropdown = !this.isShowDropdown;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
